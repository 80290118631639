import _ from 'lodash'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useChatAppConfig } from '~hooks/chatAppConfigs/useChatAppConfig'
import { onError } from '~utils/errors/onError'
import { Item } from '../Item'

export const ChatAppConfig = () => {
  const { addToast } = useToast()
  const { chatAppConfig, loading, error } = useChatAppConfig({
    onError: onError({ addToast }),
  })

  if (loading) return null
  if (error) return null
  if (!chatAppConfig) return null
  if (chatAppConfig.themeName !== 'THEME_1') return null
  if (!_.isEmpty(chatAppConfig.welcomeMessageText)) return null

  return (
    <Item
      src='/images/home/suggested/chat-app-config.png'
      width={576}
      height={352}
      title='Customize your chat and contact form widgets'
      description='Fully customisable widget for your brand identity.'
      href='/apps/contact'
      linkText='Click to customize'
    />
  )
}
