import _ from 'lodash'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useApp } from '@atlasmic/shared/hooks/apps/useApp'
import { onError } from '~utils/errors/onError'
import { Item } from '../Item'

export const TrackApp = () => {
  const { addToast } = useToast()
  const { app, loading, error } = useApp({
    variables: {
      type: 'TRACK',
    },
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  if (loading) return null
  if (error) return null
  if (app?.isEnabled) return null

  return (
    <Item
      src='/images/home/suggested/track-app.png'
      width={576}
      height={352}
      title='Turn on Track app'
      description='Learn what are your top pages, which pages have the best conversion rate and more.'
      href='/apps/track'
      linkText='Turn on Track'
    />
  )
}
