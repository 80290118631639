import _ from 'lodash'
import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { DevicesQuery } from '~graphql/queries/devices/DevicesQuery'

export const useDevices = (props) => {
  const queryProps = useQuery(DevicesQuery, props)

  return {
    devices: _.map(_.get(queryProps, 'data.devices.edges', []), 'node'),
    ...queryProps,
  }
}
