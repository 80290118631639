import { useState } from 'react'
import Link from 'next/link'
import { chevronUp, chevronDown } from '@atlasmic/icons'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { Box } from '~components/Box'
import { useSetup } from '~hooks/setups/useSetup'
import { Steps } from './Steps'

export const Setup = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { addToast } = useToast()
  const setup = useSetup({
    onError: onError({ addToast }),
  })

  if (!setup) return null

  return (
    <Box
      display='flex'
      flexDirection='column'
      marginBottom={4}
    >
      <Box
        backgroundColor='primary'
        hoverBackgroundColor='primary-darken5'
        borderRadius='rounded'
        paddingX={2}
        paddingY={1.5}
        display='flex'
        justifyContent='space-between'
        boxShadow='light'
        cursor='pointer'
        onClick={() => setIsOpen(prev => !prev)}
      >
        <Box
          display='flex'
          alignItems='center'
        >
          <Box
            textStyle='bigger-button'
            color='light'
          >
            Set up Atlasmic
          </Box>
          <Box
            paddingLeft={2}
            textStyle='text'
            color='light'
          >
            {setup.completedCount}/6 tasks completed
          </Box>
        </Box>

        <Box
          display='flex'
          alignItems='center'
        >
          <Box
            component={isOpen ? chevronUp : chevronDown}
            fill='light'
          />

          {false &&<Link
            href={setup.nextHref}
          >
            <a>
              <Box
                textStyle='text'
                backgroundColor='light'
                color='dark'
                paddingX={3}
                paddingY={0.5}
                borderRadius='lightly-rounded'
              >
                Continue setup: Install
              </Box>
            </a>
          </Link>}
          {false && <Box
            paddingLeft={2}
            textStyle='text'
            color='light'
          >
            Skip
          </Box>}
        </Box>
      </Box>

      {isOpen && <Steps />}
    </Box>
  )
}
