import _ from 'lodash'
import dayjs from 'dayjs'
import { timeConfigs } from './timeConfigs'

export const currentTimeConfig = ({
  currentTime,
}) => {
  const currentHour = dayjs(currentTime).hour()

  return _.find(timeConfigs, ({
    hours,
  }) => (
    _.includes(hours, currentHour)
  ))
}
