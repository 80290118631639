import { gql } from '@apollo/client'
import { DeviceFragment } from '~graphql/fragments/devices/DeviceFragment'

export const DevicesQuery = gql`
  query DevicesQuery {
    devices {
      edges {
        node {
          ...DeviceFragment
        }
      }
    }
  }

  ${DeviceFragment}
`
