import dayjs from 'dayjs'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { useUsersTotalCount } from '@atlasmic/shared/hooks/users/useUsersTotalCount'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { BlockSmallTitle } from '@atlasmic/shared/components/blocks/BlockSmallTitle'
import { TotalCountChangeMetric } from '@atlasmic/shared/components/metrics/TotalCountChangeMetric'
import { TrackAppStatus } from '~components/statuses/TrackAppStatus'
import { Box } from '~components/Box'
import { onError } from '~utils/errors/onError'
import { MetricBase } from '../../MetricBase'
import { MetricSkeleton } from '../../MetricSkeleton'

export const TodayUsers = ({ currentTime }) => {
  const { addToast } = useToast()
  const usersTotalCountProps = useUsersTotalCount({
    variables: {
      filter: {
        isActiveAfter: dayjs(currentTime).startOf('day').toISOString(),
      },
    },
    onError: onError({ addToast }),
  })

  const previousUsersTotalCountProps = useUsersTotalCount({
    variables: {
      filter: {
        isActiveAfter: dayjs(currentTime)
          .subtract(1, 'day')
          .startOf('day')
          .toISOString(),
        isActiveBefore: dayjs(currentTime).startOf('day').toISOString(),
      },
    },
    onError: onError({ addToast }),
  })

  return (
    <Block>
      <BlockSmallTitle>Visitors today</BlockSmallTitle>

      <Box display='flex' alignItems='center' paddingTop={0.5}>
        <MetricBase>
          {(usersTotalCountProps.loading || usersTotalCountProps.error) &&
          usersTotalCountProps.totalCount === null &&
          usersTotalCountProps.previousTotalCount === null ? (
            <MetricSkeleton />
          ) : (
            usersTotalCountProps.totalCount ??
            usersTotalCountProps.previousTotalCount
          )}
        </MetricBase>

        <TotalCountChangeMetric
          queryProps={usersTotalCountProps}
          previousQueryProps={previousUsersTotalCountProps}
        />

        <TrackAppStatus marginLeft={1} />
      </Box>
    </Block>
  )
}
