import { AuthenticatedLayout } from '~components/layouts/AuthenticatedLayout'
import { Box } from '~components/Box'
import { Meta } from '~components/Meta'
import { Greeting } from './Greeting'
import { Setup } from './Setup'
import { Statistics } from './Statistics'
import { Suggested } from './Suggested'
import { CompletedSignupModal } from './CompletedSignupModal'

export const Home = ({ blogPosts }) => (
  <AuthenticatedLayout>
    <Meta title='Dashboard - Atlasmic' />
    <Box
      paddingTop={3}
      paddingBottom={20}
      flexGrow={1}
      paddingRight={3}
      display='flex'
      flexDirection='column'
    >
      <Setup />
      <Greeting />
      <Statistics />
      <Suggested blogPosts={blogPosts} />
      <CompletedSignupModal />
    </Box>
  </AuthenticatedLayout>
)
