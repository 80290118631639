import dynamic from 'next/dynamic'
import { Box } from '~components/Box'
import { Header } from '@atlasmic/shared/components/headers/Header'
import { MobileApp } from './MobileApp'
import { UpgradePlan } from './UpgradePlan'
import { ChatAppConfig } from './ChatAppConfig'
import { TrackApp } from './TrackApp'
import { BlogPosts } from './BlogPosts'

const BrowserNotifications = dynamic(
  () =>
    import('./BrowserNotifications').then((mod) => mod.BrowserNotifications),
  { ssr: false }
)

export const Suggested = ({ blogPosts }) => (
  <Box paddingTop={4}>
    <Header>Suggested for you</Header>

    <UpgradePlan />
    <MobileApp />
    <BrowserNotifications />
    <ChatAppConfig />
    <TrackApp />
    <BlogPosts blogPosts={blogPosts} />
  </Box>
)
