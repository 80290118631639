import { Box } from '~components/Box'
import { relaxing } from '@atlasmic/illustrations'
import { Content } from './Content'

export const EmptyState = () => (
  <Box display='flex' flexDirection='column' alignItems='center' paddingY={6}>
    <Box component={relaxing} />

    <Box paddingTop={3} maxWidth={50}>
      <Content />
    </Box>
  </Box>
)
