import dynamic from 'next/dynamic'
import { useCurrentTime } from '@atlasmic/shared/hooks/times/useCurrentTime'
import { Box } from '~components/Box'
import { currentTimeConfig } from './lib/currentTimeConfig'

const Illustration = dynamic(
  () => import('./Illustration').then((mod) => mod.Illustration),
  { ssr: false }
)

const GreetingText = dynamic(
  () => import('./GreetingText').then((mod) => mod.GreetingText),
  { ssr: false }
)

export const Greeting = () => {
  const { currentTime } = useCurrentTime()

  return (
    <Box display='flex' alignItems='center'>
      <Box display='flex' flexShrink={0} width={15} height={15}>
        <Illustration currentTimeConfig={currentTimeConfig({ currentTime })} />
      </Box>

      <Box paddingLeft={4}>
        <Box textStyle='h3' color='dark' height={4}>
          <GreetingText
            currentTimeConfig={currentTimeConfig({ currentTime })}
          />
        </Box>

        <Box textStyle='text' color='dark-gray' maxWidth={90}>
          Welcome to your Atlasmic dashboard! We suggest you check your website
          and live chat statistics - eventually, that will let you grow your
          business.
        </Box>
      </Box>
    </Box>
  )
}
