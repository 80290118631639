import _ from 'lodash'
import { useDevices } from '~hooks/devices/useDevices'

export const useHasAnyDevice = (props) => {
  const queryProps = useDevices(props)

  return {
    ...queryProps,
    hasAnyDevice: !_.isEmpty(queryProps.devices),
  }
}
