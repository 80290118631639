import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useCurrentPlan } from '~hooks/plans/useCurrentPlan'
import { onError } from '~utils/errors/onError'
import { Item } from '../Item'

export const UpgradePlan = () => {
  const { addToast } = useToast()
  const { currentPlan, loading, error } = useCurrentPlan({
    onError: onError({ addToast }),
  })

  if (loading) return null
  if (error) return null
  if (!currentPlan) return null
  if (currentPlan.slug !== 'basic') return null

  return (
    <Item
      src='/images/home/suggested/upgrade-plan.png'
      width={576}
      height={352}
      title='Upgrade to Starter'
      description='Get more for your business and integrate more all-in-one ecommerce tools today!'
      href='/upgrade'
      linkText='Start your 14 day trial'
      color='money'
      hoverColor='money-darken5'
    />
  )
}
