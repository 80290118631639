import _ from 'lodash'
import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { ChatAppConfigQuery } from 'graphql/queries/chatAppConfigs/ChatAppConfigQuery'

export const useChatAppConfig = (
  { variables, ...rest } = { variables: {} }
) => {
  const currentWorkspaceProps = useCurrentWorkspace()
  const queryProps = useQuery(ChatAppConfigQuery, {
    variables: {
      workspaceId: currentWorkspaceProps.currentWorkspace?.id,
      ...variables,
    },
    skip: !currentWorkspaceProps.currentWorkspace?.id,
    ...rest,
  })

  return {
    chatAppConfig: _.get(queryProps, 'data.chatAppConfig', null),
    ...queryProps,
    loading: _.some([queryProps, currentWorkspaceProps], 'loading'),
    error: _.some([queryProps, currentWorkspaceProps], 'error'),
  }
}
