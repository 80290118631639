import Link from 'next/link'
import { TrackAppStatus as SharedTrackAppStatus } from '@atlasmic/shared/components/statuses/TrackAppStatus'

export const TrackAppStatus = (props) => (
  <Link href='/apps/track'>
    <a>
      <SharedTrackAppStatus {...props} />
    </a>
  </Link>
)
