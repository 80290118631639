import { url } from '~sanity/url'
import { Item } from '../../Item'

export const BlogPost = ({
  blogPost: {
    title,
    description,
    slug,
    mainImage,
  },
}) => (
  <Item
    src={url(mainImage).url()}
    width={1200}
    height={630}
    title={title}
    description={description}
    href={`${process.env.NEXT_PUBLIC_LANDING_URL}/blog/${slug}`}
    linkText='Read more'
  />
)
