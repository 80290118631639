import { groq } from 'next-sanity'
import { client } from '~sanity/client'
export { Home as default } from 'views/home/Home'

const blogPostsQuery = groq`*[_type == "blogPost" && isSuggestedInDashboard == true] | order(publishedAt desc) {
  _id,
  "title": select(defined(title[$locale]) => title[$locale], defined(title.en) => title.en),
  "description": select(defined(description[$locale]) => description[$locale], defined(description.en) => description.en),
  "mainImage": select(defined(mainImage[$locale]) => mainImage[$locale], defined(mainImage.en) => mainImage.en),
  "slug": slug.current,
}
`

export const getServerSideProps = async ({ res }) => {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  const blogPosts = await client.fetch(blogPostsQuery, {
    locale: 'en',
  })

  return {
    props: {
      blogPosts,
    },
  }
}
