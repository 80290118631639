import dayjs from 'dayjs'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { BlockSmallTitle } from '@atlasmic/shared/components/blocks/BlockSmallTitle'
import { useConversationsTotalCount } from '@atlasmic/shared/hooks/conversations/useConversationsTotalCount'
import { TotalCountChangeMetric } from '@atlasmic/shared/components/metrics/TotalCountChangeMetric'
import { Box } from '~components/Box'
import { MetricBase } from '../../MetricBase'
import { MetricSkeleton } from '../../MetricSkeleton'

export const TodayConversations = ({ currentTime }) => {
  const conversationsTotalCountProps = useConversationsTotalCount({
    variables: {
      filter: {
        isCreatedAtAfter: dayjs(currentTime).startOf('day').toISOString(),
      },
    },
  })

  const previousConversationsTotalCountProps = useConversationsTotalCount({
    variables: {
      filter: {
        isCreatedAtAfter: dayjs(currentTime).subtract(2, 'day').toISOString(),
        isCreatedAtBefore: dayjs(currentTime).subtract(1, 'day').toISOString(),
      },
    },
  })

  return (
    <Block>
      <BlockSmallTitle>Conversations today</BlockSmallTitle>

      <Box display='flex' alignItems='center' paddingTop={0.5}>
        <MetricBase>
          {(conversationsTotalCountProps.loading ||
            conversationsTotalCountProps.error) &&
          conversationsTotalCountProps.totalCount === null &&
          conversationsTotalCountProps.previousTotalCount === null ? (
            <MetricSkeleton />
          ) : (
            conversationsTotalCountProps.totalCount ??
            conversationsTotalCountProps.previousTotalCount
          )}
        </MetricBase>

        <TotalCountChangeMetric
          queryProps={conversationsTotalCountProps}
          previousQueryProps={previousConversationsTotalCountProps}
        />
      </Box>
    </Block>
  )
}
