import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { useIsAnyWebsiteInstalled } from '~hooks/setups/useIsAnyWebsiteInstalled'
import { useHasAnyDevice } from '~hooks/devices/useHasAnyDevice'
import { useCurrentWorkspaceHasName } from '~hooks/workspaces/useCurrentWorkspaceHasName'
import { useCurrentAgentHasName } from '~hooks/agents/useCurrentAgentHasName'
import { useIsAnyVisibleAppEnabled } from '~hooks/setups/useIsAnyVisibleAppEnabled'
import { Box } from '~components/Box'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { Step } from './Step'

export const Steps = () => {
  const { addToast } = useToast()
  const { isAnyWebsiteInstalled } = useIsAnyWebsiteInstalled({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const { hasAnyDevice } = useHasAnyDevice({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const { currentWorkspaceHasName } = useCurrentWorkspaceHasName({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { isAnyVisibleAppEnabled } = useIsAnyVisibleAppEnabled({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { currentAgentHasName } = useCurrentAgentHasName({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <Box marginTop={2}>
      <Box backgroundColor='light' borderRadius='rounded' boxShadow='light'>
        <Step index={1} isFinished>
          Create your first workspace
        </Step>
        <Step href='/install' index={2} isFinished={isAnyWebsiteInstalled}>
          Install Atlasmic to your website
        </Step>
        <Step index={3} isFinished={currentWorkspaceHasName} href='/settings'>
          Name your workspace
        </Step>
        <Step
          index={4}
          isFinished={isAnyVisibleAppEnabled}
          href='/apps/contact'
        >
          Enable Contact or Chat app to appear on your website
        </Step>
        <Step index={5} isFinished={currentAgentHasName} href='/profile'>
          Enter your name
        </Step>
        <Step
          index={6}
          isFinished={hasAnyDevice}
          href='/profile'
          borderBottom='none'
        >
          Enable browser notifications or download the mobile app
        </Step>
      </Box>
    </Box>
  )
}
