import Link from 'next/link'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useApp } from '@atlasmic/shared/hooks/apps/useApp'
import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'
import { Box } from '~components/Box'
import { onError } from '~utils/errors/onError'

export const Content = () => {
  const { addToast } = useToast()
  const { app, loading, error, data } = useApp({
    variables: {
      type: 'TRACK',
    },
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  if ((loading || error) && !data) {
    return (
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Skeleton textStyle='text' width={50} />
        <Skeleton textStyle='text' width={40} />
      </Box>
    )
  }

  if (!app?.isEnabled) {
    return (
      <Box textStyle='text' color='dark-gray' textAlign='center'>
        There is no data for you to see. You can enable{' '}
        <Link href='/apps/track'>
          <a>
            <Box
              display='inline'
              color='primary'
              hoverColor='primary-darken5'
              hoverTextDecoration='underline'
            >
              Track app
            </Box>
          </a>
        </Link>{' '}
        to get insights into your website visitors and more.
      </Box>
    )
  }

  return (
    <Box textStyle='text' color='dark-gray' textAlign='center'>
      No data yet. However, if you installed Atlasmic - sit back and relax, it
      will be shown very shortly!
    </Box>
  )
}
