import dynamic from 'next/dynamic'
import { Header } from '@atlasmic/shared/components/headers/Header'
import { Box } from '~components/Box'
import { TodayUsers } from './TodayUsers'
import { OnlineUsers } from './OnlineUsers'
import { TodayConversations } from './TodayConversations'
import { MonthConversations } from './MonthConversations'

const Timestamp = dynamic(
  () => import('./Timestamp').then((mod) => mod.Timestamp),
  { ssr: false }
)

export const LiveView = ({ currentTime }) => (
  <Box>
    <Header>
      Live view
      <Timestamp currentTime={currentTime} />
    </Header>

    <Box display='grid' gridTemplateColumns='repeat(4, 1fr)' gridGap='16px'>
      <TodayUsers currentTime={currentTime} />
      <OnlineUsers />
      <TodayConversations currentTime={currentTime} />
      <MonthConversations currentTime={currentTime} />
    </Box>
  </Box>
)
