import { Box } from '~components/Box'
import { Header } from '@atlasmic/shared/components/headers/Header'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { Content } from './Content'

export const Overview = ({
  currentTime,
}) => (
  <Box
    paddingTop={4}
  >
    <Header>
      Overview
    </Header>

    <Block>
      <Content currentTime={currentTime} />
    </Block>
  </Box>
)
