import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { useHasMobileDevice } from '~hooks/devices/useHasMobileDevice'
import { Item } from '../Item'

export const MobileApp = () => {
  const { addToast } = useToast()
  const { hasMobileDevice, loading, error } = useHasMobileDevice({
    onError: onError({ addToast }),
  })

  if (loading) return null
  if (error) return null
  if (hasMobileDevice) return null

  return (
    <Item
      src='/images/home/suggested/mobile-app.png'
      width={576}
      height={352}
      title='Download Atlasmic app for mobile'
      description='Deliver quick responses to your prospects wherever you are. Available on App Store and Google Play.'
      href='/download'
      linkText='Download now'
    />
  )
}
