import Link from 'next/link'
import { Box } from '~components/Box'
import { StepIndex } from '~components/steps/StepIndex'

export const Step = ({
  index,
  isFinished,
  href,
  borderBottom = 'separator',
  ...rest
}) => (
  <Box
    component={(!isFinished && href) ? Link : undefined}
    href={href}
  >
    <a>
      <Box
        display='flex'
        alignItems='center'
        cursor={(!isFinished && href) ? 'pointer' : undefined}
        hoverBackgroundColor={isFinished ? undefined : 'light-darken5'}
        borderBottom={borderBottom}
        padding={2}
        opacity={isFinished ? 0.5 : 1}
      >
        <StepIndex
          index={index}
          isFinished={isFinished}
        />
        <Box
          textStyle='text'
          color='dark'
          paddingLeft={1}
          {...rest}
        />
      </Box>
    </a>
  </Box>
)
