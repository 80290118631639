import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'

export const useCurrentWorkspaceHasName = (props) => {
  const { currentWorkspace, ...currentWorkspaceProps } = useCurrentWorkspace(props)

  return {
    currentWorkspaceHasName: !!currentWorkspace?.name,
    ...currentWorkspaceProps
  }
}
