import _ from 'lodash'
import { Box } from '~components/Box'
import { BlogPost } from './BlogPost'

export const BlogPosts = ({
  blogPosts,
}) => (
  <>
    {_.map(blogPosts, (blogPost) => (
      <BlogPost
        key={blogPost._id}
        blogPost={blogPost}
      />
    ))}
  </>
)
