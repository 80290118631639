import _ from 'lodash'
import { useDevices } from '~hooks/devices/useDevices'

export const useHasMobileDevice = (props) => {
  const queryProps = useDevices(props)

  return {
    ...queryProps,
    hasMobileDevice: _.some(queryProps.devices, { type: 'MOBILE' }),
  }
}
