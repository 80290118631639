import { gql } from '@apollo/client'

export const ChatAppConfigFragment = gql`
  fragment ChatAppConfigFragment on ChatAppConfig {
    id
    themeName
    defaultLanguage
    welcomeMessageText
  }
`
