import dayjs from 'dayjs'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useUsersTotalCount } from '@atlasmic/shared/hooks/users/useUsersTotalCount'
import { BlockSmallTitle } from '@atlasmic/shared/components/blocks/BlockSmallTitle'
import { TotalCountChangeMetric } from '@atlasmic/shared/components/metrics/TotalCountChangeMetric'
import { TrackAppStatus } from '~components/statuses/TrackAppStatus'
import { Box } from '~components/Box'
import { onError } from '~utils/errors/onError'
import { MetricBase } from '../../../MetricBase'
import { MetricSkeleton } from '../../../MetricSkeleton'

export const Users = ({ usersTotalCountProps, currentTime }) => {
  const { addToast } = useToast()
  const previousUsersTotalCountProps = useUsersTotalCount({
    variables: {
      filter: {
        isActiveAfter: dayjs(currentTime).subtract(60, 'days').toISOString(),
        isActiveBefore: dayjs(currentTime).subtract(30, 'days').toISOString(),
      },
    },
    onError: onError({ addToast }),
  })

  return (
    <Box>
      <BlockSmallTitle>Visitors in the last 30 days</BlockSmallTitle>

      <Box
        display='flex'
        alignItems='center'
        paddingBottom={2}
        paddingTop={0.5}
      >
        <MetricBase>
          {(usersTotalCountProps.loading || usersTotalCountProps.error) &&
          usersTotalCountProps.totalCount === null &&
          usersTotalCountProps.previousTotalCount === null ? (
            <MetricSkeleton />
          ) : (
            usersTotalCountProps.totalCount ??
            usersTotalCountProps.previousTotalCount
          )}
        </MetricBase>

        <TotalCountChangeMetric
          queryProps={usersTotalCountProps}
          previousQueryProps={previousUsersTotalCountProps}
        />

        <TrackAppStatus marginLeft={1} />
      </Box>
    </Box>
  )
}
