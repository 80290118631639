import _ from 'lodash'
import Link from 'next/link'
import Image from 'next/image'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { Box } from '~components/Box'

export const Item = ({
  title,
  description,
  src,
  width,
  height,
  href,
  linkText,
  color = 'primary',
  hoverColor = 'primary-darken5',
}) => (
  <Box marginBottom={2}>
    <Block>
      <Box display='flex'>
        <Box
          display='flex'
          width={18}
          borderRadius='lightly-rounded'
          overflow='hidden'
          flexShrink={0}
        >
          <Image src={src} width={width} height={height} objectFit='cover' />
        </Box>

        <Box paddingLeft={2}>
          <Box textStyle='bigger-button' color='dark'>
            {title}
          </Box>

          <Box textStyle='text' color='dark' paddingTop={1}>
            {description}
          </Box>

          <Link href={href}>
            <a
              target={_.startsWith(href, '/') ? undefined : '_blank'}
              rel={_.startsWith(href, '/') ? undefined : 'noopener'}
            >
              <Box
                textStyle='text'
                color={color}
                hoverColor={hoverColor}
                hoverTextDecoration='underline'
                paddingTop={1}
              >
                {linkText}
              </Box>
            </a>
          </Link>
        </Box>
      </Box>
    </Block>
  </Box>
)
