import _ from 'lodash'
import { useIsAnyWebsiteInstalled } from '~hooks/setups/useIsAnyWebsiteInstalled'
import { useHasAnyDevice } from '~hooks/devices/useHasAnyDevice'
import { useCurrentWorkspaceHasName } from '~hooks/workspaces/useCurrentWorkspaceHasName'
import { useCurrentAgentHasName } from '~hooks/agents/useCurrentAgentHasName'
import { useIsAnyVisibleAppEnabled } from '~hooks/setups/useIsAnyVisibleAppEnabled'
import { completedCount } from './lib/completedCount'

export const useSetup = (props) => {
  const isAnyWebsiteInstalledProps = useIsAnyWebsiteInstalled(props)
  const hasAnyDeviceProps = useHasAnyDevice(props)
  const currentWorkspaceHasNameProps = useCurrentWorkspaceHasName(props)
  const isAnyVisibleAppEnabledProps = useIsAnyVisibleAppEnabled(props)
  const currentAgentHasNameProps = useCurrentAgentHasName(props)

  const queryProps = [
    isAnyWebsiteInstalledProps,
    hasAnyDeviceProps,
    currentWorkspaceHasNameProps,
    isAnyVisibleAppEnabledProps,
    currentAgentHasNameProps,
  ]

  const values = [
    isAnyWebsiteInstalledProps.isAnyWebsiteInstalled,
    hasAnyDeviceProps.hasAnyDevice,
    currentWorkspaceHasNameProps.currentWorkspaceHasName,
    isAnyVisibleAppEnabledProps.isAnyVisibleAppEnabled,
    currentAgentHasNameProps.currentAgentHasName,
  ]

  if (_.some(queryProps, 'loading')) return null
  if (_.some(queryProps, 'error')) return null
  if (_.every(values)) return null

  return {
    completedCount: completedCount({
      values,
    }),
  }
}
