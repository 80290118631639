import { gql } from '@apollo/client'
import { ChatAppConfigFragment } from 'graphql/fragments/chatAppConfigs/ChatAppConfigFragment'

export const ChatAppConfigQuery = gql`
  query ChatAppConfigQuery($workspaceId: ID!) {
    chatAppConfig(workspaceId: $workspaceId) {
      ...ChatAppConfigFragment
    }
  }

  ${ChatAppConfigFragment}
`
