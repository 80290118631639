import dayjs from 'dayjs'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { useUsersTotalCount } from '@atlasmic/shared/hooks/users/useUsersTotalCount'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { BlockSmallTitle } from '@atlasmic/shared/components/blocks/BlockSmallTitle'
import { Box } from '~components/Box'
import { onError } from '~utils/errors/onError'
import { MetricBase } from '../../MetricBase'
import { MetricSkeleton } from '../../MetricSkeleton'

export const OnlineUsers = () => {
  const { addToast } = useToast()

  const usersTotalCountProps = useUsersTotalCount({
    variables: {
      filter: {
        isOnline: true,
      },
    },
    onError: onError({ addToast }),
  })

  return (
    <Block>
      <BlockSmallTitle>Online visitors</BlockSmallTitle>

      <Box display='flex' alignItems='center' paddingTop={0.5}>
        <MetricBase>
          {(usersTotalCountProps.loading || usersTotalCountProps.error) &&
          usersTotalCountProps.totalCount === null &&
          usersTotalCountProps.previousTotalCount === null ? (
            <MetricSkeleton />
          ) : (
            usersTotalCountProps.totalCount ??
            usersTotalCountProps.previousTotalCount
          )}
        </MetricBase>
      </Box>
    </Block>
  )
}
