import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useCurrentWorkspaceSubscription } from '~hooks/workspaceSubscriptions/useCurrentWorkspaceSubscription'
import { useIsAnyVisibleAppEnabled } from '~hooks/setups/useIsAnyVisibleAppEnabled'
import { useModal } from '~hooks/modals/useModal'
import { useIsSignupCompletedModalShown } from '~hooks/signup/useIsSignupCompletedModalShown'
import { ShopifyInstalledModal } from './ShopifyInstalledModal'

export const CompletedSignupModal = () => {
  const { isSignupCompletedModalShown, setIsSignupCompletedModalShown } =
    useIsSignupCompletedModalShown()

  const currentWorkspaceSubscriptionProps = useCurrentWorkspaceSubscription({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const isAnyVisibleAppEnabledProps = useIsAnyVisibleAppEnabled({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { setModal } = useModal()

  useEffect(() => {
    if (isSignupCompletedModalShown) return
    if (!currentWorkspaceSubscriptionProps.currentWorkspaceSubscription) return
    if (isAnyVisibleAppEnabledProps.loading) return
    if (isAnyVisibleAppEnabledProps.error) return
    if (isAnyVisibleAppEnabledProps.isAnyVisibleAppEnabled) return
    if (
      currentWorkspaceSubscriptionProps.currentWorkspaceSubscription.type !==
      'SHOPIFY'
    )
      return
    if (
      dayjs(
        currentWorkspaceSubscriptionProps.currentWorkspaceSubscription.createdAt
      ).isBefore(dayjs().subtract(10, 'minutes'))
    )
      return

    setIsSignupCompletedModalShown(true)
    setModal(<ShopifyInstalledModal />)
  }, [
    isSignupCompletedModalShown,
    isAnyVisibleAppEnabledProps,
    currentWorkspaceSubscriptionProps,
  ])

  return null
}
