import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'

export const useCurrentAgentHasName = (props) => {
  const { currentAgent, ...currentAgentProps } = useCurrentAgent(props)

  return {
    currentAgentHasName: !!currentAgent?.firstName || !!currentAgent?.lastName,
    ...currentAgentProps,
  }
}
