import { useCurrentTime } from '@atlasmic/shared/hooks/times/useCurrentTime'
import { Box } from '~components/Box'
import { Overview } from './Overview'
import { LiveView } from './LiveView'

export const Statistics = () => {
  const { currentTime } = useCurrentTime()

  return (
    <Box paddingTop={4}>
      <LiveView currentTime={currentTime} />
      <Overview currentTime={currentTime} />
    </Box>
  )
}
