import { Box } from '~components/Box'
import {
  checkCircleFilled,
} from '@atlasmic/icons'

export const StepIndex = ({
  index,
  isFinished,
  isActive,
  ...rest
}) => {
  if (isFinished) {
    return (
      <Box
        flexShrink={0}
        component={checkCircleFilled}
        fill='primary'
      />
    )
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      textStyle='widget-text'
      backgroundColor={isActive ? 'primary' : 'dark-gray'}
      color='light'
      borderRadius='round'
      height={3}
      width={3}
      flexShrink={0}
      {...rest}
    >
      {index}
    </Box>
  )
}
