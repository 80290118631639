import _ from 'lodash'
import { useApp } from '@atlasmic/shared/hooks/apps/useApp'

export const useIsAnyVisibleAppEnabled = (props) => {
  const contactAppProps = useApp({
    variables: {
      type: 'CONTACT',
    },
    ...props,
  })

  const chatAppProps = useApp({
    variables: {
      type: 'CHAT',
    },
    ...props,
  })

  return {
    isAnyVisibleAppEnabled:
      contactAppProps.app?.isEnabled || chatAppProps.app?.isEnabled,
    loading: _.some([contactAppProps, chatAppProps], 'loading'),
    error: _.some([contactAppProps, chatAppProps], 'error'),
    contactAppProps,
    chatAppProps,
  }
}
