import Link from 'next/link'
import { Box } from '~components/Box'
import { onboardingContact } from '@atlasmic/icons/illustrations'
import { MediumButton } from '@atlasmic/shared/components/buttons/MediumButton'
import { Button } from '@atlasmic/shared/components/buttons/Button'
import { useModal } from '~hooks/modals/useModal'
import { Modal } from '~components/modals/Modal'
import { ModalHeader } from '~components/modals/ModalHeader'
import { ModalTitle } from '~components/modals/ModalTitle'
import { ModalDescription } from '~components/modals/ModalDescription'
import { ModalButtonsContainer } from '~components/modals/ModalButtonsContainer'

export const ShopifyInstalledModal = () => {
  const { setModal } = useModal()

  return (
    <Modal>
      <ModalHeader>
        <Box component={onboardingContact} />
      </ModalHeader>
      <ModalTitle>Almost there!</ModalTitle>
      <ModalDescription>
        We’re glad to have you on board. Atlasmic platform was successfully
        installed into your Shopify store. You can now make the first app
        visible on your website -{' '}
        <Link href='/apps/contact'>
          <a>
            <Box
              display='inline'
              color='primary'
              hoverColor='primary-darken5'
              hoverTextDecoration='underline'
              onClick={() => setModal(null)}
            >
              Contact Form
            </Box>
          </a>
        </Link>
        .
      </ModalDescription>
      <ModalButtonsContainer>
        <Link href='/apps'>
          <a>
            <Button
              backgroundColor='light'
              color='primary'
              marginRight={2}
              onClick={() => setModal(null)}
            >
              Explore all apps
            </Button>
          </a>
        </Link>
        <Link href='/apps/contact'>
          <a>
            <MediumButton onClick={() => setModal(null)}>
              Activate Contact App
            </MediumButton>
          </a>
        </Link>
      </ModalButtonsContainer>
    </Modal>
  )
}
