import _ from 'lodash'
import Morning from '../../../../../../assets/illustrations/home/morning.svg'
import Afternoon from '../../../../../../assets/illustrations/home/afternoon.svg'
import Evening from '../../../../../../assets/illustrations/home/evening.svg'

export const timeConfigs = [
  {
    hours: _.range(4, 12),
    illustration: Morning,
    text: 'morning',
  },
  {
    hours: _.range(12, 17),
    illustration: Afternoon,
    text: 'afternoon',
  },
  {
    hours: [..._.range(17, 24), ..._.range(0, 4)],
    illustration: Evening,
    text: 'evening',
  },
]
