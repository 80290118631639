import { Suspense } from 'react'
import dayjs from 'dayjs'
import dynamic from 'next/dynamic'
import _ from 'lodash'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import useResizeObserver from 'use-resize-observer'
import { useUsersTotalCount } from '@atlasmic/shared/hooks/users/useUsersTotalCount'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { Loader } from '@atlasmic/shared/components/charts/VisitorsChart/Loader'
import { onError } from '~utils/errors/onError'
import { Box } from '~components/Box'
import { Users } from './Users'
import { EmptyState } from './EmptyState'

const VisitorsChart = dynamic(
  () =>
    import('@atlasmic/shared/components/charts/VisitorsChart').then(
      (mod) => mod.VisitorsChart
    ),
  {
    ssr: false,
    loading: () => <Loader />,
  }
)

dayjs.extend(utc)
dayjs.extend(timezone)

export const Content = ({ currentTime }) => {
  const { addToast } = useToast()
  const usersTotalCountProps = useUsersTotalCount({
    variables: {
      filter: {
        isActiveAfter: dayjs(currentTime).subtract(30, 'days').toISOString(),
        isActiveBefore: dayjs(currentTime).toISOString(),
      },
    },
    onError: onError({ addToast }),
  })

  const { currentWorkspace, loading } = useCurrentWorkspace({
    onError: onError({ addToast }),
  })

  const { ref, width } = useResizeObserver()

  if (!usersTotalCountProps.loading && usersTotalCountProps.totalCount === 0) {
    return <EmptyState />
  }

  return (
    <Box>
      <Users
        usersTotalCountProps={usersTotalCountProps}
        currentTime={currentTime}
      />
      <Box ref={ref} height={30} display='flex' flexDirection='column'>
        {currentWorkspace ? (
          <VisitorsChart
            currentTime={currentTime}
            timezone={currentWorkspace.timezone}
            width={width}
          />
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  )
}
